import {React, RouterDom, ui} from 'lib';
import {useOptUser, useSigninDialog, useSignoutDialog, useChangePasswordDialog, Brand} from 'shared';


export const Frame = ({brand, children}: {
    brand?: Brand;
    children: React.ReactNode;
}): React.ReactElement => {
    const user = useOptUser();
    const isAdmin = user?.role === 'admin';
    const isStaff = user?.role === 'staff' || isAdmin;
    const signin = useSigninDialog();
    const signout = useSignoutDialog();
    const changePassword = useChangePasswordDialog();
    return <ui.Container mt={5} mb={20} maxW="1200px">
        {user && <ui.HStack justifyContent="flex-end" spacing={4}>
            {isAdmin && <ui.Button as={RouterDom.Link} to="/admin" variant="link">
                Admin
            </ui.Button>}

            {isStaff && <ui.Button as={RouterDom.Link} to="/staff" variant="link">
                Staff
            </ui.Button>}

            <ui.Button onClick={changePassword.onOpen} variant="link">
                Change Password
            </ui.Button>

            <ui.Button onClick={signout.onOpen} variant="link">
                Sign Out
            </ui.Button>

            {changePassword.dialog}
            {signin.dialog}
            {signout.dialog}
        </ui.HStack>}

        {!brand && <ui.Heading
            my={10}
            fontSize={{base: '2xl', sm: '4xl'}}
            textAlign="center"
        >
            <RouterDom.Link to="/">STOCK BOOK</RouterDom.Link>
        </ui.Heading>}

        {brand && <ui.Box my={{base: 10, md: 20}}>
            <RouterDom.Link to={brand.url}>
                <ui.Img
                    src={brand.banner}
                    maxWidth="250px"
                    mx="auto"
                />
            </RouterDom.Link>
        </ui.Box>}

        <ui.Box mt={15}>
            {children}
        </ui.Box>
    </ui.Container>;
}
