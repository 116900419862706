import {React, Router, RouterDom, icons, ui, xlsx, moment} from 'lib';
import {isFunction} from 'utils';
import {useSession, useReaderApi, useStoreSelectionDialog, useStores, StoreSelection, useChildUrl, useMakeChildUrl, useUser, useSelectedItems, useResetSelectedItems, useClearZeroSelected, useSingleQueryValue, ApiSpinnerDialog, ConfirmationDialog, itemToMasterRow, Select, InputProps, useRemoteData} from 'shared';
import {Scanner} from 'barcode';
import {Store} from 'types/elcstockbook/api/data.gen';


export const StaffFrame = ({maxW, centerContent, children, headerContent, title, hideFloatingContent}: {
    children: React.ReactNode;
    headerContent?: React.ReactNode;
    title?: string;
    hideFloatingContent?: boolean;
} & Pick<Props<typeof ui.Container>, 'maxW' | 'centerContent'>): React.ReactElement => {
    const store = useStore();
    const storeSelection = useStoreSelectionDialog({
        onSelect: useSetStore(),
    });
    const backLink = useChildUrl('');
    const {pathname} = Router.useLocation();
    return <ui.Box pt="76px">
        <ui.Box
            position="fixed" top={0} left={0} right={0} height="20px"
            px={2} bgColor="black" zIndex={1001}
        >
            <ui.Text color="white" fontSize="xs" lineHeight="20px" cursor="pointer" onClick={storeSelection.onOpen}>
                {store.name}
            </ui.Text>
        </ui.Box>

        <ui.Box
            position="fixed" top="20px" left={0} right={0} height="56px"
            p={2} bgColor="white" zIndex={1001}
            borderBottom="0.5px solid" borderBottomColor="gray.300"
        >
            {headerContent}
            {!headerContent && <ui.HStack>
                <ui.Box>
                    <Menu />
                </ui.Box>
                {backLink !== pathname && <ui.Button as={RouterDom.Link} replace={true} to={backLink}>
                    Back
                </ui.Button>}
            </ui.HStack>}
            {!headerContent && !!title && <ui.Center position="absolute" width="100%" height="100%" left={0} top={0} pointerEvents="none">
                <ui.Text fontWeight="bold">{title}</ui.Text>
            </ui.Center>}
        </ui.Box>

        <ui.Container maxW={maxW} centerContent={centerContent}>
            {children}
        </ui.Container>

        {storeSelection.dialog}

        <FloatingFooterContent hide={hideFloatingContent ?? false} />
    </ui.Box>;
};


const FloatingFooterContent = React.memo(({hide}: {hide: boolean}): React.ReactElement => {
    const items = useSelectedItems();
    const reset = useResetSelectedItems();
    const clearZero = useClearZeroSelected();
    const hidden = hide || items.length === 0;
    const actionUrl = useChildUrl('action');
    return <>
        <ui.Box height={hidden ? 0 : '56px'} />
        <ui.Box
            position="fixed" bottom={hidden ? '-56px' : 0}
            left={0} right={0} height="56px"
            transition="0.2s"
            p={2} bgColor="white" zIndex={1000}
            borderTop="0.5px solid" borderBottomColor="gray.300"
        >
            <ui.Flex direction="row" alignItems="center">
                <ui.Button onClick={() => reset()} isDisabled={items.length === 0}>
                    RESET
                </ui.Button>

                <ui.Text fontSize="lg" fontWeight="bold" ml={5}>
                    {items.reduce((acc, [_, v]) => acc + v, 0)}
                </ui.Text>

                <ui.Button as={RouterDom.Link} to={actionUrl} disabled={items.length === 0} ml="auto" onClick={clearZero} colorScheme="blue">
                    Action
                </ui.Button>
            </ui.Flex>
        </ui.Box>
    </>
});



const CurrentStoreContext = React.createContext<Store | null>(null);
const StoreCodeContext = React.createContext('');


export const CurrentStoreRequired = ({children}: {
    children: React.ReactNode | {(store: Store): React.ReactNode};
}): React.ReactElement => {
    const stores = useStores();
    const code = Router.useRouteMatch<{store?: string}>().params.store ?? '';
    const store = React.useMemo(() => {
        return stores.find(s => s.code === code) ?? null;
    }, [stores, code]);

    return <StoreCodeContext.Provider value={code}>
        {!store && <StoreSelect />}

        {store && <CurrentStoreContext.Provider value={store}>
            {(isFunction(children) ? children(store) : children)}
        </CurrentStoreContext.Provider>}
    </StoreCodeContext.Provider>

};


const StoreSelect = () => {
    const setStore = useSetStore();
    return <ui.Box p={2}>
        <StoreSelection onSelect={setStore} />
    </ui.Box>
};


export const useStore = (): Store => {
    const state = React.useContext(CurrentStoreContext);
    if (!state) {
        throw new Error('useStore must called with in CurrentStoreRequired');
    }
    return state;
};


export const useSetStore = (): {(store: Store): void} => {
    const cur = React.useContext(StoreCodeContext);
    const {pathname, search} = Router.useLocation();
    const history = Router.useHistory();
    return React.useCallback((store: Store) => {
        if (store.code === cur) {
            return;
        }
        history.push({
            pathname: pathname.replace(
                new RegExp(`^${makeBaseUrl(cur)}${cur ? '' : '?'}`),
                makeBaseUrl(store.code)),
            search,
        });
    }, [cur, history, pathname, search]);
};


export const makeBaseUrl = (storeCode: string): string => `/staff/${storeCode}`;


export const useModelCodeQuery = (): ReturnType<typeof useSingleQueryValue> => {
    return useSingleQueryValue('model');
};

export const useBarcodeQuery = (): ReturnType<typeof useSingleQueryValue> => {
    return useSingleQueryValue('barcode');
};


export const Menu = React.memo((): React.ReactElement => {
    const signout = ui.useDisclosure()
    const scanner = ui.useDisclosure()
    const storeSelection = useStoreSelectionDialog({
        onSelect: useSetStore(),
    });
    const user = useUser();
    const barcodeQuery = useBarcodeQuery();
    const makeUrl = useMakeChildUrl();
    const exporter = useExporter();

    return <ui.Menu autoSelect={false}>
        <ui.MenuButton
            as={ui.IconButton}
            aria-label="Options"
            icon={<icons.HamburgerIcon />}
            variant="outline"
        />
        <ui.MenuList>
            <ui.MenuGroup>
                <ui.MenuItem as={RouterDom.Link} to={makeUrl('sold')}>
                    販売履歴
                </ui.MenuItem>
                <ui.MenuItem as={RouterDom.Link} to={makeUrl('lease_repair')}>
                    リース・修理
                </ui.MenuItem>
                <ui.MenuItem as={RouterDom.Link} to={makeUrl('keep')}>
                    予約
                </ui.MenuItem>
                <ui.MenuItem as={RouterDom.Link} to={makeUrl('waiting')}>
                    入荷連絡
                </ui.MenuItem>
                <ui.MenuItem as={RouterDom.Link} to={makeUrl('action/history')}>
                    操作履歴
                </ui.MenuItem>
                <ui.MenuItem as={RouterDom.Link} to={makeUrl('bulk')}>
                    一括操作
                </ui.MenuItem>
                <ui.MenuItem onClick={scanner.onOpen} display="none">
                    バーコード読み込み
                </ui.MenuItem>
                <ui.MenuItem onClick={exporter.confirm}>
                    エクスポート
                </ui.MenuItem>
            </ui.MenuGroup>
            <ui.MenuDivider />
            <ui.MenuGroup>
                <ui.MenuItem onClick={storeSelection.onOpen}>
                    店舗切替
                </ui.MenuItem>
            </ui.MenuGroup>
            <ui.MenuDivider />
            <ui.MenuGroup>
                {user.role === 'admin' && <>
                    <ui.MenuItem as={RouterDom.Link} to="/admin">
                        Admin
                    </ui.MenuItem>
                    <ui.MenuDivider />
                </>}
                <ui.MenuItem as={RouterDom.Link} to="/">
                    Customer
                </ui.MenuItem>
                <ui.MenuDivider />
                <ui.MenuItem onClick={signout.onOpen}>
                    Sign Out
                </ui.MenuItem>
            </ui.MenuGroup>
        </ui.MenuList>

        <SignoutDialog {...signout} />
        <ScannerDialog
            {...scanner}
            onDetected={(result) => {
                barcodeQuery.push(result);
                scanner.onClose();
            }}
        />

        {storeSelection.dialog}
        {exporter.dialog}
    </ui.Menu>
});


const SignoutDialog = ({isOpen, onClose}: {
    isOpen: boolean;
    onClose(): void;
}) => {
    const {signout} = useSession();
    const cancelRef = React.useRef<HTMLButtonElement>(null)
    return <ui.AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
    >
        <ui.AlertDialogOverlay />

        <ui.AlertDialogContent>
            <ui.AlertDialogHeader>Do you want to sign out?</ui.AlertDialogHeader>
            <ui.AlertDialogCloseButton />
            <ui.AlertDialogFooter>
                <ui.Button ref={cancelRef} onClick={onClose}>
                    No
                </ui.Button>
                <ui.Button colorScheme="red" ml={3} onClick={signout}>
                    Yes
                </ui.Button>
            </ui.AlertDialogFooter>
        </ui.AlertDialogContent>
    </ui.AlertDialog>;
};


const ScannerDialog = ({isOpen, onClose, onDetected}: {
    isOpen: boolean;
    onClose(): void;
    onDetected(code: string): void;
}) => {
    const scannerRef = React.useRef<HTMLDivElement>(null);
    const callbackRef = React.useRef(onDetected);
    callbackRef.current = onDetected;

    const detected = React.useCallback((r) => {
        callbackRef.current?.(r);
    }, [callbackRef]);

    if (!isOpen) {
        return null;
    }

    return <ui.Portal>
        <ui.Box position="fixed" top={0} left={0} right={0} bottom={0} width="100%" height="100%" maxWidth="640px" maxHeight="480px" margin="auto" background="black">
            <ui.Box ref={scannerRef} width="100%" height="100%" position="absolute" top={0} left={0}>
                <canvas className="drawingBuffer" style={{position: 'absolute', top: 0, left: 0}} />

                {isOpen && <Scanner
                    scannerRef={scannerRef}
                    onDetected={detected}
                />}

                <ui.CloseButton
                    position="absolute"
                    top={0}
                    right={0}
                    onClick={onClose}
                    zIndex={1}
                    size="lg"
                    color="white"
                />
            </ui.Box>
        </ui.Box>
    </ui.Portal>
}


const useExporter = (): {
    confirm(): void;
    dialog: React.ReactNode;
} => {
    const store = useStore();
    const api = useReaderApi('staff/get_items');
    const confirmDisclosure = ui.useDisclosure();

    const run = async () => {
        confirmDisclosure.onClose();

        type Sheet = {
            name: string;
            rows: Keyed<unknown>[]
        };

        const {sheets, cur} = (await api.call({store_id: store.id})).reduce((acc, item) => {
            const name = `${item.product.model.gender}-${item.product.model.category}`;
            const row = {
                ...itemToMasterRow(item),
                '在庫': item.stock_amount,
                '予約': item.keep_amount,
                '倉庫': item.warehouse_stock_amount,
            };
            if (acc.cur?.name === name) {
                acc.cur?.rows.push(row);
                return acc;
            } else {
                if (acc.cur) {
                    acc.sheets.push(acc.cur);
                }
                return {
                    sheets: acc.sheets,
                    cur: {
                        name,
                        rows: [row],
                    }
                };
            }
        }, {
            sheets: [] as Sheet[],
            cur: null as Sheet | null,
        });

        if (cur) {
            sheets.push(cur);
        }

        if (sheets.length === 0) {
            alert('出力するデータがありません');
            return;
        }

        const wb = xlsx.utils.book_new();
        sheets.forEach((sheet) => {
            xlsx.utils.book_append_sheet(
                wb, xlsx.utils.json_to_sheet(sheet.rows), sheet.name);
        });

        xlsx.writeFileXLSX(wb, `${store.name}-${moment().format('YYYYMMDDHHmm')}.xlsx`, {
            bookSST: true,
            compression: true,
        });
    };

    return {
        confirm: () => confirmDisclosure.onOpen(),
        dialog: <>
            <ConfirmationDialog
                title="エクスポートしますか？"
                message="この操作には時間がかかる場合があります"
                confirm="はい"
                {...confirmDisclosure}
                onConfirm={run}
            />
            <ApiSpinnerDialog api={api} />
        </>
    };
};


const getStaffs = 'staff/get_staffs';

export const StaffSelect = ({placeholder = '[choose a staff]', ...props}: {
    placeholder?: string;
} & InputProps<number | null, number | null, null>): React.ReactElement => {
    const store = useStore();
    const {data: staffs} = useRemoteData({
        path: getStaffs,
        request: {store_id: store.id},
    });

    const choices = React.useMemo(() => {
        return [
            ...(placeholder ? [{
                value: null,
                label: placeholder,
            }] : []),
            ...(staffs?.map((s) => ({
                value: s.id,
                label: s.name,
            })) ?? []),
        ];
    }, [placeholder, staffs]);

    return <Select {...props} choices={choices} />;
}
