import {React, ui, icons} from 'lib';

import {InputProps} from './binder';


type AsInputProps<C, T, CT extends T = T> = C extends React.JSXElementConstructor<infer P>
    ? P extends {value?: infer V; onChange?: {(e: infer E): void}}
        ? V extends T
            ? InputProps<T, CT, E> & Excluded<RemoveIndex<P>, 'value' | 'onChange'>
            : never
        : never
    : never;


type AsInputCustomProps<C, T, CT extends T, E> = C extends React.JSXElementConstructor<infer P>
    ? P extends {value?: infer V; onChange?: unknown}
        ? V extends T
            ? InputProps<T, CT, E> & Excluded<RemoveIndex<P>, 'value' | 'onChange'>
            : never
        : never
    : never;



export const Input = React.memo(React.forwardRef<Ref<typeof ui.Input>, AsInputProps<typeof ui.Input, string | undefined, string> & {
    onEnter?(e: React.KeyboardEvent<HTMLInputElement>): void;
}>(({
    onChange,
    onKeyPress,
    onEnter,
    ...props
}, ref) => {
    return <ui.Input
        onChange={React.useCallback((e) => onChange(e, e.target.value), [onChange])}
        onKeyPress={(e) => {
            if (onEnter && e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                onEnter(e);
            }

            return onKeyPress?.(e);
        }}
        ref={ref}
        {...props}
    />
}));
// https://github.com/chakra-ui/chakra-ui/issues/2269
Object.assign(Input, {id: 'Input'});


export const DateInput = React.memo(React.forwardRef<Ref<typeof ui.Input>, AsInputProps<typeof ui.Input, string | undefined, string>>(({
    onChange,
    ...props
}, ref) => {
    return <ui.Input
        onChange={React.useCallback((e) => e.target.value && onChange(e, e.target.value), [onChange])}
        ref={ref}
        {...props}
    />
}));


export const OptionalDateInput = React.memo(React.forwardRef<Ref<typeof ui.Input>, AsInputProps<typeof ui.Input, string | null | undefined, string | null>>(({
    value,
    onChange,
    ...props
}, ref) => {
    return <ui.Input
        value={value || ''}
        onChange={React.useCallback((e) => onChange(e, e.target.value || null), [onChange])}
        ref={ref}
        {...props}
    />
}));


export const Textarea = React.memo(({onChange, ...props}: AsInputProps<typeof ui.Textarea, string | undefined, string>) => {
    return <ui.Textarea
        onChange={React.useCallback((e) => onChange(e, e.target.value), [onChange])}
        {...props}
    />
});


export const NumberInput = React.memo(({value, onChange, ...props}: AsInputCustomProps<typeof ui.NumberInput, number | null | undefined, number, null>) => {
    const [internal, setInternal] = React.useState(String(value ?? ''))

    React.useEffect(() => {
        setInternal(String(value ?? ''));
    }, [value]);


    return <ui.NumberInput
        value={internal}
        onChange={React.useCallback((v, nv: number) => {
            setInternal(v);
            if (!isNaN(nv)) {
                onChange(null, nv);
            }
        }, [onChange])}
        {...props}
    >
        <ui.NumberInputField />
        <ui.NumberInputStepper>
            <ui.NumberIncrementStepper />
            <ui.NumberDecrementStepper />
        </ui.NumberInputStepper>
    </ui.NumberInput>
});


export const PasswordInput = React.memo(React.forwardRef<Ref<typeof ui.Input>, AsInputProps<typeof ui.Input, string | undefined, string>>(({onChange, ...props}, ref) => {
    const [showPassword, setShowPassword] = React.useState(false)

    return <ui.InputGroup>
        <ui.Input
            onChange={React.useCallback((e) => onChange(e, e.target.value), [onChange])}
            pr="4.5rem"
            type={showPassword ? 'text' : 'password'}
            placeholder="password"
            ref={ref}
            {...props}
        />
        <ui.InputRightElement width="4.5rem">
            <ui.Button h="1.75rem" size="sm" onClick={() => setShowPassword(s => !s)}>
                {showPassword ? 'Hide' : 'Show'}
            </ui.Button>
        </ui.InputRightElement>
    </ui.InputGroup>;
}));


export const SearchTextInput = React.memo(React.forwardRef<Ref<typeof ui.Input>, AsInputCustomProps<typeof ui.Input, string | undefined, string, null>>(({value, onChange, onBlur, onKeyPress, ...props}, ref) => {
    const [innerValue, setInnerValue] = React.useState('')

    React.useEffect(() => {
        setInnerValue(value || '');
    }, [value]);

    return <ui.InputGroup>
        <ui.InputLeftElement
            pointerEvents="none"
            children={<icons.SearchIcon color="gray.300" />}
        />
        <ui.Input
            {...props}
            ref={ref}
            value={innerValue}
            onChange={(e) => setInnerValue(e.target.value)}
            onBlur={(e) => {
                onBlur?.(e);
                onChange(null, innerValue);
            }}
            onKeyPress={(e) => {
                onKeyPress?.(e);
                if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    onChange(null, innerValue);
                }
            }}
        />
        {innerValue.length > 0 && <ui.InputRightElement>
            <icons.CloseIcon
                onClick={() => onChange(null, '')}
                cursor="pointer"
                color="gray.300"
            />
        </ui.InputRightElement>}
    </ui.InputGroup>
}));


export type Choice<T> = {
    value: T;
    label: string;
};

const RadioInputImpl = React.memo(React.forwardRef<Ref<typeof ui.RadioGroup>, ButChildren<AsInputCustomProps<typeof ui.RadioGroup, number | undefined, number, null>> & {
    choices: Choice<number>[];
}>(({onChange, choices, ...props}, ref) => {
    return <ui.RadioGroup
        {...props}
        onChange={React.useCallback((v) => onChange(null, parseInt(v)), [onChange])}
        colorScheme="blackAlpha"
        ref={ref}
    >
        <ui.Wrap>
            {choices.map((c, i) => <ui.WrapItem key={i}>
                <ui.Radio value={c.value}>{c.label}</ui.Radio>
            </ui.WrapItem>)}
        </ui.Wrap>
    </ui.RadioGroup>;
}));

export const RadioInput = <T, >(props: Excluded<Props<typeof RadioInputImpl>, 'value' | 'onChange' | 'choices'> & {
    choices: Choice<T>[];
} & InputProps<T | undefined, T, null>) => {
    const {value, onChange, choices, ...other} = props;
    return <RadioInputImpl
        value={choices.findIndex(c => c.value === value)}
        onChange={(e, v) => onChange(e, choices[v].value)}
        choices={choices.map((c, i) => ({value: i, label: c.label}))}
        {...other}
    />;
};

export const BoolRadioInput = (props: Excluded<Props<typeof RadioInputImpl>, 'value' | 'onChange' | 'choices'> & {
    trueLabel: string;
    falseLabel: string;
    inverted?: boolean;
} & InputProps<boolean | undefined, boolean, null>) => {
    const {value, onChange, trueLabel, falseLabel, inverted, ...other} = props;
    const choices = React.useMemo(() => [
        {label: trueLabel, value: 1},
        {label: falseLabel, value: 0},
    ], [trueLabel, falseLabel]);
    return <RadioInputImpl
        value={(value && !inverted) || (!value && inverted === true) ? 1 : 0}
        onChange={(e, v) => onChange(e, v === 1 ? !inverted : inverted === true)}
        choices={choices}
        {...other}
    />;
};


const SelectImpl = React.memo(React.forwardRef<Ref<typeof ui.Select>, ButChildren<AsInputCustomProps<typeof ui.Select, number | undefined, number, null>> & {
    choices: Choice<number>[];
}>(({onChange, choices, ...props}, ref) => {
    return <ui.Select
        {...props}
        onChange={React.useCallback((v) => onChange(null, parseInt(v.target.value)), [onChange])}
        ref={ref}
    >
        {choices.map((c, i) => <option key={i} value={c.value}>
            {c.label}
        </option>)}
    </ui.Select>;
}));

export const Select = <T, >(props: Excluded<Props<typeof SelectImpl>, 'value' | 'onChange' | 'choices'> & {
    choices: Choice<T>[];
} & InputProps<T | undefined, T, null>) => {
    const {value, onChange, choices, ...other} = props;
    return <SelectImpl
        value={choices.findIndex(c => c.value === value)}
        onChange={(e, v) => onChange(e, choices[v].value)}
        choices={choices.map((c, i) => ({value: i, label: c.label}))}
        {...other}
    />;
};


const CheckboxInputImpl = React.memo(({value, onChange, choices}: {
    choices: Choice<number>[];
} & InputProps<number[] | undefined, number[], null>) => {
    const toggle = (v: number) => {
        const filtered = value?.filter(_v => _v !== v) ?? [];
        onChange(null, filtered.length === (value?.length ?? 0) ? filtered.concat([v]) : filtered);
    };
    return <ui.Wrap>
        {choices.map((c, i) => <ui.WrapItem key={i}>
            <ui.Checkbox
                isChecked={value?.includes(c.value) ?? false}
                onChange={() => toggle(c.value)}
                colorScheme="blackAlpha"
            >
                {c.label}
            </ui.Checkbox>
        </ui.WrapItem>)}
    </ui.Wrap>;
});

export const CheckboxInput = <T, >(props: Excluded<Props<typeof CheckboxInputImpl>, 'value' | 'onChange' | 'choices'> & {
    choices: Choice<T>[];
} & InputProps<T[] | undefined, T[], null>) => {
    const {value, onChange, choices, ...other} = props;
    return <CheckboxInputImpl
        value={value?.map(v => choices.findIndex(c => c.value === v)) ?? []}
        onChange={(e, v) => onChange(e, v.map(i => choices[i].value))}
        choices={choices.map((c, i) => ({value: i, label: c.label}))}
        {...other}
    />;
};


export const BoolCheckboxInput = (props: Excluded<Props<typeof CheckboxInputImpl>, 'value' | 'onChange' | 'choices'> & {
    label: string;
} & InputProps<boolean | undefined, boolean, null>): React.ReactElement => {
    const {value, onChange, label, ...other} = props;
    const choices = React.useMemo(() => [
        {label, value: 1},
    ], [label]);
    return <CheckboxInputImpl
        value={value ? [1] : []}
        onChange={(e, v) => onChange(e, v.length > 0)}
        choices={choices}
        {...other}
    />;
};


export const FileInput = React.memo(({value, onChange, accept, onReadData, onReadDataAsArrayBuffer}: {
    accept: string;
    onReadData?(base64data: string): void;
    onReadDataAsArrayBuffer?(buffer: ArrayBuffer): void;
} & InputProps<File | undefined, File, React.ChangeEvent<HTMLInputElement>>) => {
    const inputRef = React.useRef<HTMLInputElement>(null);

    return <ui.HStack spacing={4}>
        <ui.Box width="120px" flexShrink={0} flexGrow={0}>
            <ui.Button onClick={() => inputRef.current?.click()}>
                {value ? 'Change' : 'Select'} File
            </ui.Button>
        </ui.Box>

        <ui.Box>
            {value && <ui.Text mt={1}>
                {value.name}
            </ui.Text>}

            <form style={{display: 'none'}}>
                <input
                    type="file"
                    accept={accept}
                    multiple={false}
                    ref={inputRef}
                    onChange={(e) => {
                        const file = e.target.files?.[0];
                        if (!file) return;
                        e.target.form?.reset();
                        onChange(e, file);

                        if (onReadData) {
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = () => {
                                onReadData(reader.result as string);
                            };
                        }

                        if (onReadDataAsArrayBuffer) {
                            const reader = new FileReader();
                            reader.readAsArrayBuffer(file);
                            reader.onload = () => {
                                onReadDataAsArrayBuffer(reader.result as ArrayBuffer);
                            };
                        }
                    }}
                />
            </form>
        </ui.Box>
    </ui.HStack>
});
