import {React, Router, ui} from 'lib';

import {useRemoteData, useWriterApi, Req, Res, Input, PasswordInput, SearchTextInput, RadioInput, CheckboxInput, BoolRadioInput, Textarea, LayoutItem, FormItem, useForm, ApiSpinnerDialog, ApiCompletionDialog, ApiErrorDialog, InputProps, Paginator, usePage, Choice, makeOptionalBrandChoices, makeBrandChoices, QRCode} from 'shared';
import {Store} from 'types/elcstockbook/api/data.gen';

const listPath = 'admin/user/list';
const createPath = 'admin/user/create';
const updatePath = 'admin/user/update';
const listInvitationsPath = 'admin/user/list_invitations';
const createInvitationPath = 'admin/user/create_invitation';
const updateInvitationPath = 'admin/user/update_invitation';

type User = ArrayItem<Res<typeof listPath>['items']>;
type Role = User['role'];
type Invitation = ArrayItem<Res<typeof listInvitationsPath>['items']>;
type AdminData = Res<'admin/get_data'>;

export const UserIndex = (): React.ReactElement => {
    const location = Router.useLocation();
    const history = Router.useHistory();
    const {url} = Router.useRouteMatch();

    const users = `${url}`;
    const customers = `${url}/customer`;
    const invitations = `${url}/invitation`;

    const tabs = [users, customers, invitations];
    const index = tabs.indexOf(location.pathname)

    const {data} = useRemoteData({
        path: 'admin/get_data',
        request: {},
    });

    if (!data) {
        return <ui.Spinner />;
    }

    return <>
        <ui.Tabs index={index} onChange={i => history.push(tabs[i])}>
            <ui.TabList>
                <ui.Tab>管理者・スタッフ一覧</ui.Tab>
                <ui.Tab>顧客一覧</ui.Tab>
                <ui.Tab>招待一覧</ui.Tab>
            </ui.TabList>

            <ui.TabPanels>
                {tabs.map((_, i) => <ui.TabPanel key={i}>
                    {i === index && <Router.Switch>
                        <Router.Route path={customers}>
                            <UserList customer={true} data={data} />
                        </Router.Route>
                        <Router.Route path={invitations}>
                            <InvitationList data={data} />
                        </Router.Route>
                        <Router.Route path={users}>
                            <UserList customer={false} data={data} />
                        </Router.Route>
                    </Router.Switch>}
                </ui.TabPanel>)}
            </ui.TabPanels>
        </ui.Tabs>
    </>;
}

const UserList = ({customer, data}: {
    customer: boolean;
    data: AdminData;
}) => {
    const roles: Role[] = React.useMemo(() => {
        return customer ? ['customer'] : ['admin', 'staff'];
    }, [customer]);
    const {binder} = useForm<Req<typeof listPath>>(() => ({
        text: '',
        brand: null,
        for_customer: customer,
        role: roles.length === 1 ? roles[0] : null,
        disabled: null,
    }));

    const roleChoices = useRoleChoices(roles);
    const disabledChoices = React.useMemo(() => {
        return [
            {value: null, label: '指定なし'},
            {value: false, label: '利用可能'},
            {value: true, label: '利用停止'},
        ];
    }, []);

    const {data: users, reload} = useRemoteData({
        path: listPath,
        request: {
            ...binder.value,
            page: usePage(),
        },
    });
    const [editing, setEditing] = React.useState<User>();
    const createDisclosure = ui.useDisclosure();
    const updateDisclosure = ui.useDisclosure({
        isOpen: editing !== undefined,
        onClose: () => setEditing(undefined),
    });

    return <ui.Box>
        {!customer && <LayoutItem>
            <ui.Button onClick={createDisclosure.onOpen}>
                Add
            </ui.Button>
        </LayoutItem>}

        <FormItem>
            <SearchTextInput
                {...binder.mapInputProps('text')}
            />
        </FormItem>

        {roles.length > 1 && <FormItem>
            <RadioInput
                {...binder.mapInputProps('role')}
                choices={[
                    {value: null, label: '指定なし'},
                    ...roleChoices
                ]}
            />
        </FormItem>}

        <FormItem>
            <RadioInput
                {...binder.mapInputProps('brand')}
                choices={makeOptionalBrandChoices('指定なし')}
            />
        </FormItem>

        <FormItem>
            <RadioInput
                {...binder.mapInputProps('disabled')}
                choices={disabledChoices}
            />
        </FormItem>

        <ui.Table mt={10}>
            <ui.Thead>
                <ui.Tr>
                    <ui.Th>ID</ui.Th>
                    <ui.Th>Name</ui.Th>
                    <ui.Th>Email</ui.Th>
                    {roles.length > 1 && <ui.Th>Role</ui.Th>}
                    <ui.Th>Suspension</ui.Th>
                </ui.Tr>
            </ui.Thead>
            <ui.Tbody>
                {users?.items.map((user) => <ui.Tr key={user.id}>
                    <ui.Td>
                        <ui.Link color="blue.500" onClick={() => setEditing(user)}>
                            {user.id}
                        </ui.Link>
                    </ui.Td>
                    <ui.Td>{user.name}</ui.Td>
                    <ui.Td>{user.email}</ui.Td>
                    {roles.length > 1 && <ui.Td>{user.role}</ui.Td>}
                    <ui.Td>{user.disabled ? '利用停止' : ''}</ui.Td>
                </ui.Tr>)}
            </ui.Tbody>
        </ui.Table>

        {users && <ui.Box mt={6}>
            <Paginator {...users} />
        </ui.Box>}

        <CreateUserDialog
            roles={roles}
            stores={data.stores}
            onComplete={() => {
                createDisclosure.onClose();
                reload();
            }}
            {...createDisclosure}
        />
        <UpdateUserDialog
            stores={data.stores}
            user={editing}
            onComplete={() => {
                updateDisclosure.onClose();
                reload();
            }}
            {...updateDisclosure}
        />
    </ui.Box>
};



const CreateUserDialog = ({stores, roles, onComplete, ...props}: {
    stores: Store[];
    roles: Role[];
    onComplete(user: User): void;
} & ui.UseDisclosureReturn) => {
    const api = useWriterApi(createPath);
    const {binder, handleSubmit, reset} = useForm<Req<typeof createPath>, 'role'>(() => ({
        name: '',
        email: '',
        password: '',
        memo: '',
        role: roles[0],
        brands: [],
        stores: [] as number[],
    }));

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, reset]);

    const roleChoices = useRoleChoices(roles);

    const submit = handleSubmit(data => api.call(data));

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">ユーザーの登録</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <form onSubmit={submit}>
                    <FormItem
                        label="name"
                        keyPath="name"
                        error={api.error?.data}
                    >
                        <Input
                            {...binder.mapInputProps('name')}
                        />
                    </FormItem>

                    <FormItem
                        label="email"
                        keyPath="email"
                        error={api.error?.data}
                    >
                        <Input
                            type="email"
                            {...binder.mapInputProps('email')}
                        />
                    </FormItem>

                    <FormItem
                        label="password"
                        keyPath="password"
                        error={api.error?.data}
                    >
                        <PasswordInput
                            {...binder.mapInputProps('password')}
                        />
                    </FormItem>

                    <FormItem
                        label="role"
                        keyPath="role"
                        error={api.error?.data}
                    >
                        {roleChoices.length > 1 && <RadioInput<Role>
                            choices={roleChoices}
                            {...binder.mapInputProps('role')}
                        />}
                        {roleChoices.length === 1 && <ui.Text>
                            {roleChoices[0].label}
                        </ui.Text>}
                    </FormItem>

                    {binder.value.role && binder.value.role !== 'customer' && <FormItem
                        label="stores"
                        keyPath="stores"
                        error={api.error?.data}
                    >
                        <CheckboxInput
                            choices={stores.map(s => ({
                                value: s.id,
                                label: s.name,
                            }))}
                            {...binder.mapInputProps('stores')}
                        />
                    </FormItem>}

                    {binder.value.role === 'customer' && <FormItem
                        label="brands"
                        keyPath="brand"
                        error={api.error?.data}
                    >
                        <CheckboxInput
                            choices={makeBrandChoices()}
                            {...binder.mapInputProps('brands')}
                        />
                    </FormItem>}

                    <FormItem
                        label="memo"
                        keyPath="memo"
                        error={api.error?.data}
                    >
                        <Textarea {...binder.mapInputProps('memo')} />
                    </FormItem>
                </form>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={submit}
                >
                    作成
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiErrorDialog api={api} onOk={api.reset} />
        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={(data) => {
                onComplete(data);
                reset();
                api.reset();
            }}
        />
    </ui.Modal>;
};



const UpdateUserDialog = ({stores, user, onComplete, ...props}: {
    stores: Store[];
    user: User | undefined;
    onComplete(user: User): void;
} & ui.UseDisclosureReturn) => {
    const api = useWriterApi(updatePath);
    const {binder, handleSubmit, reset} = useForm<Req<typeof updatePath>, keyof Req<typeof updatePath>>(() => ({
        id: user?.id,
        name: user?.name,
        email: user?.email,
        password: undefined,
        memo: user?.memo ?? '',
        role: user?.role,
        brands: user?.brands ?? [],
        disabled: user?.disabled,
        stores: user?.stores.map(s => s.id),
    }));

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, user, reset]);

    const roleChoices = useRoleChoices(React.useMemo(() => {
        return user?.role === 'customer' ? ['customer'] : ['staff', 'admin'];
    }, [user?.role]));

    const submit = handleSubmit(data => api.call(data));

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">ユーザーの編集</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <form onSubmit={submit}>
                    <FormItem
                        label="name"
                        keyPath="name"
                        error={api.error?.data}
                    >
                        <Input
                            {...binder.mapInputProps('name')}
                        />
                    </FormItem>

                    <FormItem
                        label="email"
                        keyPath="email"
                        error={api.error?.data}
                    >
                        <Input
                            type="email"
                            {...binder.mapInputProps('email')}
                        />
                    </FormItem>

                    <FormItem
                        label="password"
                        keyPath="password"
                        error={api.error?.data}
                    >
                        <RadioInput
                            value={binder.value.password === undefined ? 'no' : 'yes'}
                            onChange={(_e, value) => {
                                binder.assign({password: value === 'yes' ? '' : undefined});
                            }}
                            choices={[
                                {value: 'no', label: '変更しない'},
                                {value: 'yes', label: '変更する'},
                            ]}
                        />
                        {typeof binder.value.password === 'string' && <PasswordInput
                            {...binder.mapInputProps('password') as InputProps<string, string>}
                        />}
                    </FormItem>

                    <FormItem
                        label="role"
                        keyPath="role"
                        error={api.error?.data}
                    >
                        {roleChoices.length > 1 && <RadioInput<Role>
                            choices={roleChoices}
                            {...binder.mapInputProps('role')}
                        />}
                        {roleChoices.length === 1 && <ui.Text>
                            {roleChoices[0].label}
                        </ui.Text>}
                    </FormItem>

                    {binder.value.role !== 'customer' && <FormItem
                        label="stores"
                        keyPath="stores"
                        error={api.error?.data}
                    >
                        <CheckboxInput
                            choices={stores.map(s => ({
                                value: s.id,
                                label: s.name,
                            }))}
                            {...binder.mapInputProps('stores')}
                        />
                    </FormItem>}

                    {binder.value.role === 'customer' && <FormItem
                        label="brands"
                        keyPath="brand"
                        error={api.error?.data}
                    >
                        <CheckboxInput
                            choices={makeBrandChoices()}
                            {...binder.mapInputProps('brands')}
                        />
                    </FormItem>}

                    <FormItem
                        label="memo"
                        keyPath="memo"
                        error={api.error?.data}
                    >
                        <Textarea {...binder.mapInputProps('memo')} />
                    </FormItem>

                    <FormItem
                        label="suspension"
                        keyPath="disabled"
                        error={api.error?.data}
                    >
                        <BoolRadioInput
                            inverted={true}
                            trueLabel="アカウント利用可能"
                            falseLabel="利用停止"
                            {...binder.mapInputProps('disabled')}
                        />
                    </FormItem>
                </form>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={submit}
                >
                    更新
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiErrorDialog api={api} onOk={api.reset} />
        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={(data) => {
                onComplete(data);
                reset();
                api.reset();
            }}
        />
    </ui.Modal>;
};


const useRoleChoices = (roles: Role[]): Choice<User['role']>[] => {
    return React.useMemo(() => [
        {value: 'customer' as const, label: '顧客'},
        {value: 'staff' as const, label: 'スタッフ'},
        {value: 'admin' as const, label: '管理者'},
    ].filter(c => roles.includes(c.value)), [roles]);
};


const InvitationList = ({data}: {
    data: AdminData;
}) => {
    const {binder} = useForm<Req<typeof listInvitationsPath>>(() => ({
        text: '',
        brand: null,
        accepted: null,
    }));

    const acceptedChoices = React.useMemo(() => {
        return [
            {value: null, label: '指定なし'},
            {value: true, label: '承諾済み'},
            {value: false, label: '未承諾'},
        ];
    }, []);

    const {data: invitations, reload} = useRemoteData({
        path: listInvitationsPath,
        request: {
            ...binder.value,
            page: usePage(),
        },
    });
    const [editing, setEditing] = React.useState<Invitation>();
    const [editingUser, setEditingUser] = React.useState<User>();
    const createDisclosure = ui.useDisclosure();
    const updateDisclosure = ui.useDisclosure({
        isOpen: editing !== undefined,
        onClose: () => setEditing(undefined),
    });
    const updateUserDisclosure = ui.useDisclosure({
        isOpen: editingUser !== undefined,
        onClose: () => setEditingUser(undefined),
    });

    return <ui.Box>
        <LayoutItem>
            <ui.Button onClick={createDisclosure.onOpen}>
                Add
            </ui.Button>
        </LayoutItem>

        <FormItem>
            <SearchTextInput
                {...binder.mapInputProps('text')}
            />
        </FormItem>

        <FormItem>
            <RadioInput
                {...binder.mapInputProps('brand')}
                choices={makeOptionalBrandChoices('指定なし')}
            />
        </FormItem>

        <FormItem>
            <RadioInput
                {...binder.mapInputProps('accepted')}
                choices={acceptedChoices}
            />
        </FormItem>

        <ui.Table mt={10}>
            <ui.Thead>
                <ui.Tr>
                    <ui.Th>ID</ui.Th>
                    <ui.Th>Name</ui.Th>
                    <ui.Th>Memo</ui.Th>
                    <ui.Th>Brand</ui.Th>
                    <ui.Th>Accepted User</ui.Th>
                </ui.Tr>
            </ui.Thead>
            <ui.Tbody>
                {invitations?.items.map((inv) => <ui.Tr key={inv.id}>
                    <ui.Td>
                        <ui.Link color="blue.500" onClick={() => setEditing(inv)}>
                            {inv.id}
                        </ui.Link>
                    </ui.Td>
                    <ui.Td>{inv.name}</ui.Td>
                    <ui.Td>{inv.memo}</ui.Td>
                    <ui.Td>{inv.brand}</ui.Td>
                    <ui.Td>
                        {inv.user && <ui.Link color="blue.500" onClick={() => setEditingUser(inv.user ?? undefined)}>
                            ({inv.user.id}) {inv.user.name}
                        </ui.Link>}
                    </ui.Td>
                </ui.Tr>)}
            </ui.Tbody>
        </ui.Table>

        {invitations && <ui.Box mt={6}>
            <Paginator {...invitations} />
        </ui.Box>}

        <InvitationCreateDialog
            onComplete={(invitation) => {
                createDisclosure.onClose();
                reload();
                setEditing(invitation);
            }}
            {...createDisclosure}
        />
        <InvitationUpdateDialog
            invitation={editing}
            onClickUser={(user) => {
                updateDisclosure.onClose();
                setEditingUser(user);
            }}
            onComplete={() => {
                updateDisclosure.onClose();
                reload();
            }}
            {...updateDisclosure}
        />
        <UpdateUserDialog
            stores={data.stores}
            user={editingUser}
            onComplete={() => {
                updateUserDisclosure.onClose();
                reload();
            }}
            {...updateUserDisclosure}
        />
    </ui.Box>
};


const InvitationCreateDialog = ({onComplete, ...props}: {
    onComplete(invitation: Invitation): void;
} & ui.UseDisclosureReturn) => {
    const api = useWriterApi(createInvitationPath);
    const {binder, handleSubmit, reset} = useForm<Req<typeof createInvitationPath>, 'brand'>(() => ({
        name: '',
        memo: '',
        brand: undefined,
    }));

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, reset]);

    const submit = handleSubmit(data => api.call(data));

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">招待の作成</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <form onSubmit={submit}>
                    <FormItem
                        label="name"
                        keyPath="name"
                        error={api.error?.data}
                    >
                        <Input
                            {...binder.mapInputProps('name')}
                        />
                    </FormItem>

                    <FormItem
                        label="brand"
                        keyPath="brand"
                        error={api.error?.data}
                    >
                        <RadioInput
                            choices={makeBrandChoices()}
                            {...binder.mapInputProps('brand')}
                        />
                    </FormItem>

                    <FormItem
                        label="memo"
                        keyPath="memo"
                        error={api.error?.data}
                    >
                        <Textarea {...binder.mapInputProps('memo')} />
                    </FormItem>
                </form>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={submit}
                >
                    作成
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiErrorDialog api={api} onOk={api.reset} />
        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={(data) => {
                onComplete(data);
                reset();
                api.reset();
            }}
        />
    </ui.Modal>;
};



const InvitationUpdateDialog = ({invitation, onClickUser, onComplete, ...props}: {
    invitation: Invitation | undefined;
    onClickUser(user: User): void;
    onComplete(invitation: Invitation): void;
} & ui.UseDisclosureReturn) => {
    const api = useWriterApi(updateInvitationPath);
    const {binder, handleSubmit, reset} = useForm<Req<typeof updateInvitationPath>, keyof Req<typeof updateInvitationPath>>(() => ({
        id: invitation?.id,
        name: invitation?.name,
        memo: invitation?.memo ?? '',
        brand: invitation?.brand,
    }));

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, invitation, reset]);

    const submit = handleSubmit(data => api.call(data));

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">招待の編集</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <form onSubmit={submit}>
                    {invitation && !invitation.user && <FormItem
                        label={`このURLを${invitation.name}様に共有してください`}
                    >
                        <ui.Input
                            readOnly={true}
                            value={invitation.url}
                            onFocus={(e) => {
                                const target = e.target;
                                setTimeout(() => {
                                    target.select();
                                    target.setSelectionRange(0, target.value.length);
                                }, 100);
                            }}
                        />

                        <ui.Box textAlign="center" mt={2} ml="auto" mr="auto" width="256px">
                            <QRCode value={invitation.url} size={256} />
                        </ui.Box>
                    </FormItem>}

                    {invitation && invitation.user && <FormItem
                        label="顧客情報"
                        keyPath="url"
                        error={api.error?.data}
                    >
                        <ui.Link color="blue.500" onClick={() => invitation.user && onClickUser(invitation.user)}>
                            {invitation.user.name}
                        </ui.Link>
                    </FormItem>}

                    <FormItem
                        label="name"
                        keyPath="name"
                        error={api.error?.data}
                    >
                        <Input
                            {...binder.mapInputProps('name')}
                        />
                    </FormItem>

                    <FormItem
                        label="brand"
                        keyPath="brand"
                        error={api.error?.data}
                    >
                        <RadioInput
                            choices={makeBrandChoices()}
                            {...binder.mapInputProps('brand')}
                        />
                    </FormItem>

                    <FormItem
                        label="memo"
                        keyPath="memo"
                        error={api.error?.data}
                    >
                        <Textarea {...binder.mapInputProps('memo')} />
                    </FormItem>
                </form>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={submit}
                >
                    更新
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiErrorDialog api={api} onOk={api.reset} />
        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={(data) => {
                onComplete(data);
                reset();
                api.reset();
            }}
        />
    </ui.Modal>;
};
