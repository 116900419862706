import {React, Router, RouterDom, ui, icons} from 'lib';
import {useRemoteData, Res, Paginator, S3Image, useSingleQueryValue, useSingleQueryValueAsNumber, useSingleQueryValueAs, Brand, Choice, SearchTextInput} from 'shared';
import {formatComma, addTax} from 'utils';
import {Store} from 'types/elcstockbook/api/data.gen';

import {Frame} from './frame';


const masterPath = 'customer/get_master_data';
type Master = Res<typeof masterPath>;


export function Main({brand}: {brand: Brand}) {
    return <Frame brand={brand}>
        <ModelList brand={brand} />
    </Frame>;
}


const ModelList = ({brand}: {brand: Brand}) => {
    const {data: master} = useRemoteData({
        path: masterPath,
        request: {brand: brand.value},
    });
    const {search} = Router.useLocation();

    const page = useSingleQueryValueAsNumber('page');
    const keyword = useKeyword();
    const gender = useGender();
    const store = useStore(master);
    const season = useSeason(master);
    const category = useCategory(master);
    const line = useLine(master);

    const {data} = useRemoteData({
        path: 'customer/list_models',
        request: {
            page: page.value ?? 1,
            keyword: keyword.value,
            gender: gender.value,
            brand: brand.value,
            store_id: store.store?.id,
            season: season.value,
            category: category.value,
            line: line.value,
        },
        halt: !master,
    });

    React.useEffect(() => {
        const elm = window.document.scrollingElement ?? window.document.body;
        elm.scrollTop = 0;
    }, [page.value]);

    React.useEffect(() => {
        if ((page?.value ?? 1) > 1 && data?.items.length === 0) {
            page.replace(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, data, gender.value, store.store?.id]);

    if (!data || !master) {
        return <ui.Box>
            <ui.HStack my={10} justifyContent="center">
                <ui.Spinner
                    size="xl"
                    speed="1.0s"
                />
            </ui.HStack>
        </ui.Box>
    }

    return <ui.Box>
        <ui.Wrap align="center" justify="right" spacing={3} mt={2}>
            <ui.Box width={{base: '100%', md: '300px'}}>
                {keyword.node}
            </ui.Box>
            <ui.Spacer />
            {gender.node}
            {line.node}
            {category.node}
            {season.node}
            {store.node}
        </ui.Wrap>

        {data.items.length === 0 && <ui.Box my={20}>
            <ui.Text textAlign="center" color="gray.500" size="lg">
                There are no matching items
            </ui.Text>
        </ui.Box>}

        <ui.Box mx="-1em">
            <ui.Flex flexDirection="row" flexWrap="wrap" my={10}>
                {data?.items.map((p) => {
                    type Item = ArrayItem<typeof p['items']>;
                    type Color = Item['color'];
                    type Store = Item['store'];
                    const {colors, stores, dcodes} = p.items.reduce((acc, item) => {
                        if (!(item.color.color in acc.colors)) {
                            acc.colors[item.color.color] = item.color;
                        }
                        if (!(item.store.id in acc.stores)) {
                            acc.stores[item.store.id] = item.store;
                        }
                        if (!(item.domestic_code in acc.dcodes)) {
                            acc.dcodes[item.domestic_code] = item.domestic_code;
                        }
                        return acc;
                    }, {
                        colors: {} as Keyed<Color>,
                        stores: {} as Keyed<Store>,
                        dcodes: {} as Keyed<string>,
                    });
                    return <ui.Box
                        as={RouterDom.Link}
                        to={`${brand.url}/${p.code}${search}`}
                        width={{base: '100%', md: '50%'}}
                        key={p.code}
                        p={2}
                        cursor="pointer"
                        transition="0.5s"
                        sx={{'&:hover': {
                            backgroundColor: '#E1E1E1',
                        }}}
                    >
                        <ui.HStack>
                            <ui.Box width={{base: '140px', sm: '50%'}} textAlign="right" flexShrink={0} flexGrow={0}>
                                <S3Image
                                    display="inline-block"
                                    base={p.images[0]?.base_url}
                                    processor="sd256"
                                    width={{base: '140px', sm: '192px', lg: '256px'}}
                                    height={{base: '140px', sm: '192px', lg: '256px'}}
                                    objectFit="contain"
                                    border="none"
                                />
                            </ui.Box>
                            <ui.Box fontSize={{base: 'sm', sm: 'md'}} ml={4} flexShrink={1} flexGrow={1}>
                                <ui.Text fontWeight="bold">
                                    {p.name}
                                </ui.Text>

                                <ui.Text>
                                    {p.code}
                                </ui.Text>

                                {brand.attrname !== 'rickowens' && Object.keys(dcodes).sort().map(c => c !== p.code && <ui.Text key={c}>
                                    {c}
                                </ui.Text>)}

                                <ui.Text fontSize="sm" mt={1}>
                                    ¥{formatComma(addTax(p.price))}
                                </ui.Text>

                                <ui.Text fontSize="xs" mt={1}>
                                    {Object.keys(colors).length} color{Object.keys(colors).length > 1 ? 's' : ''}
                                </ui.Text>

                                <ui.Text fontSize="xs">
                                    {Object.values(stores).map(s => s.name).join(' / ')}
                                </ui.Text>
                            </ui.Box>
                        </ui.HStack>
                    </ui.Box>;
                })}
            </ui.Flex>
        </ui.Box>

        {data && <Paginator {...data} />}
    </ui.Box>
};


const useKeyword = (): {
    value: string;
    node: React.ReactNode;
} => {
    const query = useSingleQueryValue('keyword');

    return React.useMemo(() => {
        const value = query.value ?? '';
        return {
            value,
            node: <ui.Box>
                <SearchTextInput
                    value={value}
                    onChange={(_, v) => query.push(v || null, {page: null})}
                />
            </ui.Box>,
        };
    }, [query]);
};


type Gender = 'm' | 'w' | 'k';

const useGender = (): {
    value: Gender | null;
    node: React.ReactNode;
} => {
    const query = useSingleQueryValueAs<Gender>('gender', {
        to: (v) => ['m', 'w', 'k'].includes(v) ? v as Gender : null,
        from: (v) => v,
    })

    const choices: Choice<Gender | null>[] = React.useMemo(() => [
        {value: null, label: 'GENDER'},
        {value: 'm', label: 'MEN'},
        {value: 'w', label: 'WOMEN'},
        {value: 'k', label: 'KIDS'},
    ], []);

    return React.useMemo(() => {
        const choice = choices.find(({value}) => value === query.value) ?? null;
        const value = choice?.value ?? null;
        return {
            value: value,
            node: <ui.Box>
                <ui.Menu>
                    <ui.MenuButton>
                        <ui.Text fontSize={{base: 'xs', sm: 'sm', md: 'md'}}>
                            <icons.TriangleDownIcon /> {choice?.label ?? 'GENDER'}
                        </ui.Text>
                    </ui.MenuButton>
                    <ui.MenuList>
                        <ui.MenuItem onClick={() => query.push(null, {page: null})}>
                            ALL
                        </ui.MenuItem>
                        {choices.map((c) => c.value && <ui.MenuItem key={c.value} onClick={() => query.push(c.value, {page: null})}>
                            {c.label}
                        </ui.MenuItem>)}
                    </ui.MenuList>
                </ui.Menu>
            </ui.Box>,
        };
    }, [query, choices]);
};


const useStore = (master: Master | null): {
    store: Store | null;
    node: React.ReactNode;
} => {
    const query = useSingleQueryValueAsNumber('store');
    const stores = master?.stores;

    return React.useMemo(() => {
        const storeId = query.value;
        const store = stores?.find(s => s.id === storeId) ?? null;
        return {
            value: storeId,
            store,
            node: (stores ?? []).length > 1 && <ui.Box>
                <ui.Menu>
                    <ui.MenuButton>
                        <ui.Text fontSize={{base: 'xs', sm: 'sm', md: 'md'}}>
                            <icons.TriangleDownIcon /> {store?.name ?? 'STORE'}
                        </ui.Text>
                    </ui.MenuButton>
                    <ui.MenuList>
                        <ui.MenuItem onClick={() => query.push(null, {page: null})}>
                            ALL
                        </ui.MenuItem>
                        {stores?.map((s) => <ui.MenuItem key={s.id} onClick={() => query.push(s.id, {page: null})}>
                            {s.name}
                        </ui.MenuItem>)}
                    </ui.MenuList>
                </ui.Menu>
            </ui.Box>,
        };
    }, [query, stores]);
};


const useSeason = (master: Master | null): {
    value: string | null;
    node: React.ReactNode;
} => {
    const query = useSingleQueryValue('season');
    const seasons = master?.seasons;

    return React.useMemo(() => {
        const season = seasons?.find(s => s === query.value) ?? null;
        return {
            value: season,
            node: (seasons ?? []).length > 1 && <ui.Box>
                <ui.Menu>
                    <ui.MenuButton>
                        <ui.Text fontSize={{base: 'xs', sm: 'sm', md: 'md'}}>
                            <icons.TriangleDownIcon /> {season ?? 'SEASON'}
                        </ui.Text>
                    </ui.MenuButton>
                    <ui.MenuList>
                        <ui.MenuItem onClick={() => query.push(null, {page: null})}>
                            All
                        </ui.MenuItem>
                        {seasons?.map((s) => <ui.MenuItem key={s} onClick={() => query.push(s, {page: null})}>
                            {s}
                        </ui.MenuItem>)}
                    </ui.MenuList>
                </ui.Menu>
            </ui.Box>,
        };
    }, [query, seasons]);
};


const useCategory = (master: Master | null): {
    value: string | null;
    node: React.ReactNode;
} => {
    const query = useSingleQueryValue('category');
    const categories = master?.categories;

    return React.useMemo(() => {
        const category = categories?.find(s => s === query.value) ?? null;
        return {
            value: category,
            node: (categories ?? []).length > 1 && <ui.Box>
                <ui.Menu>
                    <ui.MenuButton>
                        <ui.Text fontSize={{base: 'xs', sm: 'sm', md: 'md'}}>
                            <icons.TriangleDownIcon /> {category ?? 'CATEGORY'}
                        </ui.Text>
                    </ui.MenuButton>
                    <ui.MenuList>
                        <ui.MenuItem onClick={() => query.push(null, {page: null})}>
                            All
                        </ui.MenuItem>
                        {categories?.map((s) => <ui.MenuItem key={s} onClick={() => query.push(s, {page: null})}>
                            {s}
                        </ui.MenuItem>)}
                    </ui.MenuList>
                </ui.Menu>
            </ui.Box>,
        };
    }, [query, categories]);
};


const useLine = (master: Master | null): {
    value: string | null;
    node: React.ReactNode;
} => {
    const query = useSingleQueryValue('line');
    const lines = master?.lines;

    return React.useMemo(() => {
        const line = lines?.find(s => s === query.value) ?? null;
        return {
            value: line,
            node: (lines ?? []).length > 1 && <ui.Box>
                <ui.Menu>
                    <ui.MenuButton>
                        <ui.Text fontSize={{base: 'xs', sm: 'sm', md: 'md'}}>
                            <icons.TriangleDownIcon /> {line ?? 'LINE'}
                        </ui.Text>
                    </ui.MenuButton>
                    <ui.MenuList>
                        <ui.MenuItem onClick={() => query.push(null, {page: null})}>
                            All
                        </ui.MenuItem>
                        {lines?.map((s) => <ui.MenuItem key={s} onClick={() => query.push(s, {page: null})}>
                            {s}
                        </ui.MenuItem>)}
                    </ui.MenuList>
                </ui.Menu>
            </ui.Box>,
        };
    }, [query, lines]);
};
