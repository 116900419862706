import {Brand as B} from 'types/elcstockbook/api/data.gen';

export type Brand = B & {
    url: string;
    img: string;
    banner: string;
};

export const brands: Brand[] = [
    {
        attrname: 'rickowens',
        url: '/rickowens',
        value: 'Rick Owens',
        img: '/static/rickowens.png',
        banner: '/static/rickowens_banner.png',
    },
    {
        attrname: 'offwhite',
        url: '/offwhite',
        value: 'OFF WHITE',
        img: '/static/offwhite.png',
        banner: '/static/offwhite_banner.png',
    },
    {
        attrname: 'ragbone',
        url: '/ragbone',
        value: 'rag&bone',
        img: '/static/ragbone.png',
        banner: '/static/ragbone_banner.png',
    },
];


type BrandMap = {[K in B['attrname']]: Brand};
export const brandMap: BrandMap = brands.reduce((acc, b) => {
    acc[b.attrname] = b;
    return acc;
}, {} as BrandMap);


export const makeBrandChoices = (): {value: Brand['value'], label: string}[] => {
    return brands.map(b => ({value: b.value, label: b.value}));
};


export const makeOptionalBrandChoices = (optionLabel: string = '指定なし'): {value: Brand['value'] | null, label: string}[] => {
    return [
        {value: null, label: optionLabel},
        ...makeBrandChoices(),
    ];
};
