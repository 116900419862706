import {Router, ui} from 'lib';

import {useRemoteData, useForm, useWriterApi, useSessionData, Req, ApiErrorDialog, ApiSpinnerDialog, ApiCompletionDialog, FormItem, Input, PasswordInput, brands} from 'shared';

import {Frame} from './frame';


export const Invitation = (): React.ReactElement => {
    const key = Router.useRouteMatch<{key?: string}>().params.key ?? '';
    const data = useRemoteData({
        path: 'lookup_invitation',
        request: {key},
    });
    const brandValue = data.data;
    return <Frame>
        {data.state !== 'success' && <ui.Box>
            <ui.HStack my={10} justifyContent="center">
                <ui.Spinner
                    size="xl"
                    speed="1.0s"
                />
            </ui.HStack>
        </ui.Box>}

        {data.state === 'success' && !brandValue && <ui.Box>
            <ui.Text color="gray.500" textAlign="center">
                URLが無効です
            </ui.Text>
        </ui.Box>}

        {data.state === 'success' && data.data && <ui.Box>
            <ui.Text color="gray.500" fontWeight="bold" textAlign="center" mb={8}>
                {data.data}に招待されています
            </ui.Text>

            <AcceptForm
                invitationKey={key}
                onComplete={() => {
                    const brand = brands.filter(b => b.value === brandValue)[0].attrname;
                    window.location.replace(`/${brand}`);
                }}
            />
        </ui.Box>}
    </Frame>
};


const AcceptForm = ({invitationKey: key, onComplete}: {
    invitationKey: string;
    onComplete(): void;
}) => {
    const path = 'accept_invitation';
    const session = useSessionData();
    const api = useWriterApi(path);
    const {binder, handleSubmit, reset} = useForm<Req<typeof path>, keyof Req<typeof path>>(() => ({
        key,
        email: session.email ?? '',
        password: session.email ? 'dummy password' : '',
    }));

    const submit = handleSubmit(data => api.call(data));

    return <ui.Box maxWidth="300px" ml="auto" mr="auto">
        {!session.email && <form onSubmit={submit}>
            <ui.Box mb={6}>
                メールアドレスとパスワードを入力して会員登録してください
            </ui.Box>

            <FormItem
                label="email"
                keyPath="email"
                error={api.error?.data}
            >
                <Input
                    {...binder.mapInputProps('email')}
                />
            </FormItem>

            <FormItem
                label="password"
                keyPath="password"
                error={api.error?.data}
            >
                <PasswordInput
                    {...binder.mapInputProps('password')}
                />
            </FormItem>
        </form>}

        <ui.Box textAlign="center" mt={8}>
            <ui.Button
                colorScheme="blue"
                onClick={submit}
            >
                {session.email ? '招待を承諾する' : '会員登録して招待を承諾する'}
            </ui.Button>
        </ui.Box>

        <ApiErrorDialog api={api} onOk={api.reset} />
        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={() => {
                onComplete();
                reset();
                api.reset();
            }}
        />
    </ui.Box>;
};
