import {React, ui} from 'lib';

import {isFunction} from 'utils';
import {User} from 'types/elcstockbook/api/data.gen';

import {useSession, Req, useWriterApi} from './api';
import {useForm} from './form';
import {Input, PasswordInput} from './input';
import {ApiErrorText, ApiCompletionDialog, LoadingScreen, FormItem, LayoutItem} from './components';


export const useOptUser = (): User | null => {
    return useSession().data?.user ?? null;
};

const UserContext = React.createContext<User | null>(null);

export const useUser = (): User => {
    const user = React.useContext(UserContext);
    if (!user) {
        throw new Error('Can not call `useUser` outside out LoginRequired');
    }
    return user;
};

export const WithUser = ({children}: {
    children(user: User): React.ReactElement | null;
}): React.ReactElement | null => children(useUser());


export const LoginRequired = ({children}: {
    children: React.ReactNode | {(user: User): React.ReactNode};
}): React.ReactElement => {
    const {data: {user}, loading} = useSession();

    if (!user) {
        if (loading) {
            return <LoadingScreen />
        } else {
            return <LoginScreen />
        }
    }

    return <UserContext.Provider value={user}>
        {isFunction(children) ? children(user) : children}
    </UserContext.Provider>;
};


export const useSigninDialog = (): {
    dialog: React.ReactElement;
} & ui.UseDisclosureReturn => {
    const disclosure = ui.useDisclosure();
    return React.useMemo(() => ({
        ...disclosure,
        dialog: <SigninDialog {...disclosure} />,
    }), [disclosure]);
};


const SigninDialog = (props: ui.UseDisclosureReturn) => {
    const ref = React.useRef<Ref<typeof SigninForm>>(null);
    React.useEffect(() => {
        if (props.isOpen) {
            ref.current?.reset();
        }
    }, [props.isOpen]);
    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">Sign In</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <SigninForm ref={ref} onComplete={props.onClose} />
            </ui.ModalBody>

            <ui.ModalFooter />
        </ui.ModalContent>
    </ui.Modal>;
}


export const LoginScreen = (): React.ReactElement => {
    return <ui.Container centerContent height="500px">
        <ui.Flex height="100%" alignItems="center" direction="row">
            <ui.VStack width="300px">
                <ui.Box mb={5}>
                    <ui.Text fontSize="xl" fontWeight="bold">ログイン</ui.Text>
                </ui.Box>

                <ui.Box width="100%">
                    <SigninForm />
                </ui.Box>
            </ui.VStack>
        </ui.Flex>
    </ui.Container>;
};


const SigninForm = React.forwardRef<{
    reset(): void;
}, {
    onComplete?(): void;
}>(({onComplete}, ref) => {
    const session = useSession();

    const path = 'signin';
    const api = useWriterApi(path);
    const {binder, handleSubmit, reset} = useForm<Req<typeof path>>({
        email: '',
        password: '',
    });
    const submit = handleSubmit(async (data) => {
        const {user} = await api.call(data);
        if (user) {
            session.reload();
            onComplete?.();
        }
    });

    React.useImperativeHandle(ref, () => ({
        reset,
    }));

    return <ui.Box>
        <FormItem
            label="メールアドレス"
            keyPath="email"
            error={api.error?.data}
        >
            <Input
                type="email"
                placeholder="email"
                {...binder.mapInputProps('email')}
            />
        </FormItem>

        <FormItem
            label="パスワード"
            keyPath="password"
            error={api.error?.data}
        >
            <PasswordInput
                {...binder.mapInputProps('password')}
            />
        </FormItem>

        <ApiErrorText error={api.error} />

        <LayoutItem.Group>
            <LayoutItem>
                <ui.Button onClick={submit} width="100%" isDisabled={api.loading || session.loading}>
                    ログイン
                </ui.Button>
            </LayoutItem>
        </LayoutItem.Group>
    </ui.Box>
});


export const useChangePasswordDialog = (): {
    dialog: React.ReactElement;
} & ui.UseDisclosureReturn => {
    const disclosure = ui.useDisclosure();
    return React.useMemo(() => ({
        ...disclosure,
        dialog: <ChangePasswordDialog {...disclosure} />,
    }), [disclosure]);
};


const ChangePasswordDialog = (props: ui.UseDisclosureReturn) => {
    const path = 'change_password';
    const api = useWriterApi(path);
    const {binder, handleSubmit, reset} = useForm<Req<typeof path>>({
        password: '',
    });
    const submit = handleSubmit(async (data) => {
        await api.call(data);
    });

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, reset]);

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">Change Password</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <ui.Box>
                    <FormItem
                        label="変更後のパスワード"
                        keyPath="password"
                        error={api.error?.data}
                    >
                        <PasswordInput
                            {...binder.mapInputProps('password')}
                        />
                    </FormItem>

                    <ApiErrorText error={api.error} />

                    <LayoutItem.Group>
                        <LayoutItem>
                            <ui.Button onClick={submit} width="100%" isDisabled={api.loading}>
                                変更する
                            </ui.Button>
                        </LayoutItem>
                    </LayoutItem.Group>
                </ui.Box>
            </ui.ModalBody>

            <ui.ModalFooter />
        </ui.ModalContent>

        <ApiCompletionDialog
            api={api}
            onOk={() => {
                props.onClose();
                reset();
                api.reset();
            }}
        />
    </ui.Modal>;
}
