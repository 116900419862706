import {Router, ui, RouterDom} from 'lib';

import {brands, useSessionData} from 'shared';

import {Detail} from './detail';
import {Main} from './main';
import {Frame} from './frame';

export {Invitation} from './invitation';


export const Customer = () => {
    const {search} = Router.useLocation();
    const {push} = Router.useHistory();

    return <Router.Switch>
        {brands.map((b) => <Router.Route path={b.url} key={b.value}>
            <Main brand={b} />
            <Router.Route path={`${b.url}/:code`}>
                {(p) => <ui.Modal
                    size="xl"
                    isOpen={!!p.match}
                    onClose={() => push(`${b.url}${search}`)}
                >
                    <ui.ModalOverlay />
                    <ui.ModalContent>
                        <ui.ModalCloseButton />
                        <ui.ModalBody>
                            <Detail brand={b} code={p.match?.params.code ?? ''} />
                        </ui.ModalBody>
                    </ui.ModalContent>
                </ui.Modal>}
            </Router.Route>
        </Router.Route>)}

        <Router.Route path="/">
            <Index />
        </Router.Route>
    </Router.Switch>
};


const Index = () => {
    const session = useSessionData();
    return <Frame>
        <ui.Flex flexDirection="row" flexWrap="wrap" my={10}>
            {brands.map(b => session.brands.includes(b.value) && <ui.Box
                key={b.attrname}
                as={RouterDom.Link}
                to={b.attrname}
                width={{base: '100%', sm: '50%', md: '33.33%'}}
                p={2}
                cursor="pointer"
                transition="0.5s"
                sx={{'&:hover': {
                    backgroundColor: '#E1E1E1',
                }}}
            >
                <ui.Img src={b.img} />
            </ui.Box>)}
        </ui.Flex>
    </Frame>
};
