import {QRCodeCanvas} from 'qrcode.react';


export type QRCodeProps = {
    value: string;
    size: number;
};

export const QRCode = ({value, size}: QRCodeProps): React.ReactElement => {
    return <QRCodeCanvas
        value={value}
        size={size}
    />;
};

